import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'activity',
    component: () => import('../views/Activity.vue')
  }, {
    path: '/download',
    name: 'download',
    component: () => import('../views/Download.vue')
  }, {
    path: '/protocol',
    name: 'protocol',
    component: () => import('../views/Protocol.vue')
  }, {
    path: '/merchant',
    name: 'merchant',
    component: () => import('../views/Merchant.vue')
  }, {
    path: '/cumen',
    name: 'cumen',
    component: () => import('../views/Cumen.vue')
  }, {
    path: '/club_week',
    name: 'club_week',
    component: () => import('../views/ClubWeek.vue')
  }, {
    path: '/frisbee_planet',
    name: 'frisbee_planet',
    component: () => import('../views/FrisbeePlanet.vue')
  }, {
    path: '/alipay',
    name: 'alipay',
    component: () => import('../views/alipay.vue')
  }, {
    path: '/share-details',
    component: () => import('../views/share/details.vue'),
  }, {
    path: '/share-details-poster',
    component: () => import('../views/share/details-poster.vue'),
  }, {
    path: '/share-album',
    component: () => import('../views/share/album.vue'),
  }, {
    path: '/share-leagues',
    component: () => import('../views/share/leagues.vue'),
  }, {
    path: '/share-leagues-poster',
    component: () => import('../views/share/leagues-poster.vue'),
  }, {
    path: '/share-profile',
    component: () => import('../views/share/profile.vue'),
  }, {
    path: '/share-ticket',
    component: () => import('../views/share/ticket.vue'),
  },{
    path: '/share-green-card-poster',
    component: () => import('../views/share/green-card-poster.vue'),
  }, {
    path: '/share-leagues-career',
    component: () => import('../views/share/leagues-career.vue'),
  }, {
    path: '/share-medal',
    component: () => import('../views/share/medal.vue'),
  }, {
    path: '/share-score-sheet',
    component: () => import('../views/share/score-sheet.vue'),
  }, {
    path: '/share-leagues-rank',
    component: () => import('../views/share/leagues-rank.vue'),
  }, {
    path: '/share-invite-admin',
    component: () => import('../views/share/invite-admin.vue')
  }, {
    path: '/campaign-links',
    component: () => import('../views/CampaignLinks.vue')
  }, {
    path: '/route',
    component: () => import('../views/route/index.vue'),
    children: [
      {
        path: 'map-to-image',
        component: () => import('../views/route/map-to-image.vue')
      }
    ],
  }, {
    path: "/share-shop",
    component: () => import('../views/share/shop.vue')
  }, {
    path: "/share-shop-poster",
    component: () => import('../views/share/shop-poster.vue')
  }, {
    path: "/protocol-goods",
    component: () => import('../views/protocol/goods.vue')
  },
  {
    path: "/game-stub",
    component: () => import('../views/share/game-stub')
  },
  {
    path: "/share-lottery-poster",
    component: () => import('../views/share/lottery-poster')
  },
  {
    path: "/operating-statement",
    component: () => import('../views/operating-statement')
  },
  {
    path: "/athletic",
    component: () => import('../views/athletic')
  },
  {
    path: "/share-goods",
    component: () => import('../views/share/goods.vue')
  },
  {
    path: "/share-goods-poster",
    component: () => import('../views/share/goods-poster.vue')
  },
  {
    path: "/share-partner-profile-poster",
    component: () => import('../views/share/partner-profile-poster.vue')
  },
  {
    path: "/nina",
    component: () => import('../views/nina.vue')
  },
  {
    path: "/ad",
    component: () => import('../views/share/ad.vue')
  },
  {
    path: "/jjdjyfl",
    component: () => import('../views/jjdjyfl.vue')
  },
  {
    path: "/ad-dewu",
    component: () => import('../views/ad/dewu.vue')
  },
  {
    path: "/professional",
    component: () => import('../views/professional.vue')
  },
  {
    path: "/attractions",
    component: () => import('../views/share/attractions.vue')
  },
  {
    path: "/annual-report/index",
    component: () => import('../views/annual-report/index.vue')
  },
  {
    path: "/annual-report/guide-download",
    component: () => import('../views/annual-report/guide-download.vue')
  },
  {
    path: "/launch-cumen-messages",
    component: () => import('../views/redirect/CumenMessages.vue')
  },
  {
    path: "/wxmp/club/index",
    component: () => import('../views/wxmp/club/index.vue')
  },
  {
    path: "/share-scenic-poster",
    component: () => import('../views/share/scenic-poster.vue')
  }, {
    path: '/app',
    component: () => import('../views/CumenApp.vue')
  }, {
    path: "/route-team-invitation",
    component: () => import('../views/RouteTeamInvitation.vue')
  }, {
    path: "/gift-clothes",
    component: () => import('../views/gift-clothes.vue')
  }, {
    path: "/share-step-challenge",
    component: () => import('../views/share/share-step-challenge.vue')
  }, {
    path: "/generateCode",
    component: () => import('../views/generateCode.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
